<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/2 md:w-1/2 mt-base">
        <vs-list-header
          title="Ordens de Serviço"
          color="primary"
        ></vs-list-header>
         <div style="display:inline-block;margin-right:20px;">
            <label style="color: black; font-size: 13px;">Zona</label><br>
            <el-select filterable clearable class="select-info"
              v-model="zona">
              <el-option v-for="zona in zonas"
                        class="select-info"
                        :value="zona.id"
                        :label="zona.nome"
                        :key="zona.id">
              </el-option>
            </el-select>
          </div>
        <vs-table
          multiple
          v-model="selected"
          @selected="handleSelected"
          :data="list1"
        >
          <template slot="thead">
            <vs-th>Email</vs-th>
            <vs-th>Name</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :state="data[indextr].lixeira == 1 ? 'danger' : ''"
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
              <vs-td :data="data[indextr].os">{{ data[indextr].os }}</vs-td>

              <vs-td :data="data[indextr].email">{{
                data[indextr].email
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <div class="vx-col w-1/2 md:w-1/2 mt-base">
        <vs-list>
          <vs-list-header title="Tecnico" color="primary"></vs-list-header>
          <div style="display:inline-block;margin-right:20px;">
            <label style="color: black; font-size: 13px;">Técnico</label><br>
            <el-select filterable clearable class="select-info"
              v-model="tecnico">
              <el-option v-for="tecnico in tecnicos"
                        class="select-info"
                        :value="tecnico.id"
                        :label="tecnico.nome"
                        :key="tecnico.id">
              </el-option>
            </el-select>
          </div>
          <draggable
            :list="osAssociada"
            @change="addOsToTec($event)"
            group="people"
            class="p-2 cursor-move"
          >
            <vs-list-item
              v-for="(listItem, index) in osAssociada"
              :key="index"
              :title="listItem.name"
              :subtitle="listItem.email"
            >
              <vs-avatar slot="avatar" :text="listItem.name" />
            </vs-list-item>
          </draggable>
        </vs-list>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  data() {
    return {
      list1: [
        {
          os: "35345435",
          email: "girliness@spotlike.co.uk"
        },
        {
          os: "24234234",
          email: "executrixship@equisized.edu"
        },
        {
          os: "3243243",
          email: "bidding@demibob.or"
        },
        {
          os: "2343243",
          email: "preholding@scuffly.co.uk"
        }
      ],
      osAssociada: [],
      osAssociadaPearTec: [],
      tecnico: {},
      tecnicos: [],
      zona: {},
      zonas: [
        { id: 0, nome: "Sul" },
        { id: 1, nome: "Oeste" },
        { id: 2, nome: "Leste" },
        { id: 3, nome: "Norte" },
        { id: 4, nome: "Centro" },
        { id: 5, nome: "Rural" }
      ]
    };
  },
  mounted() {
    this.getTecnicos();
  },
  methods: {
    getTecnicos() {
      this.$http
        .get("https://emops.com.br/world/webservice-cod/tecnicos")
        .then(tecnicos => (this.tecnicos = tecnicos.data))
        .catch(err => console.log(err));
    },
    addOsToTec(event) {
      this.osAssociadaPearTec.push({
        idTec: this.tecnico,
        os: event.added.element.os
      });
    },
    getOsAssociadaPearTec(event) {
      this.osAssociada = this.osAssociadaPearTec.filter(x => (x.idTec = event));
    }
  },
  components: {
    draggable
  },
  activated() {
    this.$vs.loading();
    setTimeout(() => {
      this.$vs.loading.close();
    }, 200);
  }
};
</script>
